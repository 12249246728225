.bg-app{
  background-color: rgb(245, 244, 244);
}

.header-logo-text {
  padding: 1px;
  font-weight: bold;
  font-family: fantasy;
  font-size: medium;
}

.footer-unsubscribe-txt {
  color: white;
  text-decoration: none;
}




